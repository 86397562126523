import { faJs, faPython, faSlack } from '@fortawesome/free-brands-svg-icons';
import { faHand } from '@fortawesome/free-regular-svg-icons';
import {
  faBook,
  faBookOpenReader,
  faBug,
  faClipboardCheck,
  faCommentsDollar,
  faFlask,
  faHashtag,
  faServer,
  faTerminal,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, getErrorMessage } from '../common/utils';
import HomepageTile from '../components/homepage/HomepageTile';
import { getPromptsOverviewMetrics } from '../services/Analytics';
import { getPipelines } from '../services/PipelineEvaluations';
import { getPrompts } from '../services/Prompts';
import { getUser } from '../services/User';
import { PromptMetricOverview, UserBasicInfo } from '../types';

interface Props {}

interface DevResource {
  text: string;
  url: string;
  icon: IconDefinition;
}

const WHATS_NEW: (string | React.ReactElement)[] = [
  'Prompts page now has a list view.',
  'History page now supports refreshing without losing filters.',
  'Model parameter values can be directly entered by clicking the value.',
  'Prompts can now be favorited.'
];

const DEV_RESOURCES: DevResource[] = [
  { text: 'App Guide', url: '/help', icon: faBookOpenReader },
  { text: 'API Documentation', url: '/api/docs', icon: faBook },
  {
    text: 'Slack #promptly',
    url: 'https://coxauto.slack.com/archives/C06V80TMKED',
    icon: faSlack
  },
  { text: 'Python Client Library', url: 'https://ghe.coxautoinc.com/CoxAuto/promptly-python-library', icon: faPython },
  {
    text: 'Typescript Client Library',
    url: 'https://ghe.coxautoinc.com/CoxAuto/promptly-typescript-library',
    icon: faJs
  },
  {
    text: 'C# Client Library',
    url: 'https://ghe.coxautoinc.com/CoxAuto/promptly-csharp-client',
    icon: faHashtag
  },
  {
    text: 'C# Consumer Library',
    url: 'https://ghe.coxautoinc.com/CoxAuto/promptly-csharp-consumer',
    icon: faHashtag
  },
  {
    text: 'Report Issue',
    url: 'https://ghe.coxautoinc.com/CoxAuto/promptly/issues/new?assignees=&labels=&projects=&template=bug_report.md&title=',
    icon: faBug
  },
  {
    text: 'Feature Request',
    url: 'https://ghe.coxautoinc.com/CoxAuto/promptly/issues/new?assignees=&labels=&projects=&template=feature_request.md&title=',
    icon: faHand
  }
];

/**
 * Represents the Home page component.
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const Home: React.FC<Props> = ({}: Props) => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserBasicInfo>();
  const [promptCount, setPromptCount] = useState<number>();
  const [metrics, setMetrics] = useState<PromptMetricOverview>();
  const [pipelineCount, setPipelineCount] = useState<number>();

  useEffect(() => {
    /**
     * Fetches user info, app count, and metrics data asynchronously.
     */
    (async () => {
      try {
        const [_userInfo, _prompts, _metrics, _pipelines] = await Promise.all([
          getUser(true),
          getPrompts(),
          getPromptsOverviewMetrics(),
          getPipelines()
        ]);

        setUserInfo(_userInfo);
        setPromptCount(_prompts.filter((p) => !p.archived).length);
        setMetrics(_metrics);
        setPipelineCount(_pipelines.filter((p) => !p.archived).length);
      } catch (error) {
        console.error(error);
        const msg = getErrorMessage(error);
        if (msg === 'Invalid credentials') return;

        return toast.error(msg);
      }
    })();
  }, []);

  return (
    <>
      <h2 className="text-2xl text-gray-800 mb-4 ">
        <span className="text-3xl pr-2">👋</span>
        {!userInfo ? (
          <Skeleton containerClassName="inline-block w-32" />
        ) : (
          <span>Welcome back, {userInfo?.name.split(' ')[0]}!</span>
        )}
      </h2>
      <p className="text-lg text-gray-600">Here&apos;s a quick overview of your recent activity.</p>

      <ul className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-6 justify-normal mt-9 max-w-screen-xl">
        <HomepageTile
          title="Active Prompts"
          icon={faTerminal}
          text={promptCount}
          onClick={() => navigate('/prompts/list')}
        />
        <HomepageTile
          title="Calls"
          icon={faServer}
          text={metrics?.totalInvocations?.toLocaleString() || undefined}
          onClick={() => navigate('/analytics')}
        />
        <HomepageTile
          title="Cost"
          icon={faCommentsDollar}
          text={metrics ? formatCurrency(metrics.totalCost) : undefined}
          onClick={() => navigate('/analytics')}
        />
        <HomepageTile
          title="Pipelines"
          icon={faFlask}
          text={pipelineCount !== undefined ? pipelineCount : undefined}
          onClick={() => navigate('/pipelines')}
        />
      </ul>

      <div className="grid xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-6 justify-items-start mt-20 border-t border-gray-200">
        <div>
          <h2 className="text-2xl text-gray-800 pt-10 mb-4">What&apos;s New</h2>
          <p className="text-lg text-gray-600 mb-9">Here&apos;s the latest from the world of Promptly.</p>
          {WHATS_NEW.map((item, index) => (
            <div key={index} className="flex items-start mt-4">
              <FontAwesomeIcon icon={faClipboardCheck} className="mr-2.5 mt-1 text-indigo-400" />
              <p className="text-gray-500">{item}</p>
            </div>
          ))}
        </div>
        <div>
          <h2 className="text-2xl text-gray-800   pt-10 mb-4">Developer Resources</h2>
          <p className="text-lg text-gray-600 mb-9">Tools and resources to help you get the most out of Promptly.</p>
          <ul className="grid xl-grid-cols-2 lg:grid-cols-2 sm:grid-cols-1">
            {DEV_RESOURCES.map((item, index) => (
              <li key={index} className="mb-4 flex items-center">
                <FontAwesomeIcon icon={item.icon} className="mr-2.5 text-indigo-400" />
                <a href={item.url} target="_blank" rel="noreferrer" className="text-gray-500 font-normal">
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Home;
