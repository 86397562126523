import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import { ErrorFallback } from './components';
import { oktaConfig } from './config';
import { SHARED_PROMPT_PATH } from './constants';
import { AdminLayout, DefaultLayout, ProtectedLayout, ProtectedNoLayout, PublicLayout } from './layouts';
import { history } from './lib/history';
import { Account, CallHistory, Home, NotFound, Performance } from './pages';
import { Admin, AdminUser, Users } from './pages/admin/';
import DocsPage from './pages/Docs';
import { Experiment, Experimentation } from './pages/experimentation';
import { Pipeline, PipelineRun, Pipelines } from './pages/pipelines';
import { ManagePrompt, NewPrompt, Prompt, PromptAnalytics, Prompts, SharedPrompt } from './pages/prompts';
import { Login, LoginCallback, Register, ResetPassword } from './pages/users';
import { WebhookList } from './pages/webhooks';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function App() {
  const [confirmAuth] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (confirmAuth) {
        await oktaAuth.signInWithRedirect();
      }
    })();
  }, [confirmAuth]);

  const restoreOriginalUri = async (_: any, originalUri: string) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Toaster position="top-center" toastOptions={{ style: { maxWidth: 600, width: 320 } }} reverseOrder={false} />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset/:resetToken" element={<ResetPassword />} />
          </Route>
          <Route element={<PublicLayout />}>
            <Route path={`${SHARED_PROMPT_PATH}/:sharedPromptId`} element={<SharedPrompt />} />
          </Route>
          <Route element={<ProtectedLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/account" element={<Account />} />
            <Route path="/prompts/list/:type?" element={<Prompts />} />
            <Route path="/prompts/new/:type?" element={<NewPrompt />} />
            <Route path="/prompts/:promptId" element={<Prompt />} />
            <Route path="/prompts/:promptId/manage" element={<ManagePrompt />} />
            <Route path="/prompts/:promptId/:version" element={<Prompt />} />
            <Route path="/history" element={<CallHistory />} />
            <Route path="/analysis" element={<Performance />} />
            <Route path="/analytics" element={<PromptAnalytics />} />
            <Route path="/experimentation" element={<Experimentation />} />
            <Route path="/experimentation/:experimentId" element={<Experiment />} />
            <Route path="/pipelines" element={<Pipelines />} />
            <Route path="/pipelines/:pipelineId" element={<Pipeline />} />
            <Route path="/pipelines/:pipelineId/runs/:runId" element={<PipelineRun />} />
            <Route path="/webhooks" element={<WebhookList />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route element={<ProtectedNoLayout />}>
            <Route path="/help" element={<DocsPage />} />
          </Route>
          <Route element={<AdminLayout />}>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/users/:userId" element={<AdminUser />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    </Security>
  );
}
export default App;
